(function () {
	'use strict';
	angular
		.module('event')
		.controller('RepeatCtrl', [
			'$sce',
			'$scope',
			'$timeout',
			'seedcodeCalendar',
			'repeating',
			'utilities',
			RepeatCtrl,
		]);

	function RepeatCtrl(
		$sce,
		$scope,
		$timeout,
		seedcodeCalendar,
		repeating,
		utilities
	) {
		//Load config from our model and watch for future updates
		$scope.calendar = {};
		$scope.calendar.config = seedcodeCalendar.get('config');

		//edit object passed into scope
		$scope.edit = $scope.data;

		$scope.calendarId = $scope.edit.schedule.id;

		if ($scope.edit.recurrenceSettings) {
			$scope.settings = $scope.edit.recurrenceSettings;
			//we're only showing one at a time, so for now we can set all to true
			$scope.settings.toggleNever = true;
			$scope.settings.toggleUntil = $scope.calendar.config.advancedRepeats
				? false
				: true;
			$scope.settings.toggleCount = true;
		} else {
			//defaults
			$scope.settings = {};
			$scope.settings.panel = 'Daily';
			$scope.settings.interval = 1;
			//we're only showing one at a time, so for now we can set all to true
			$scope.settings.toggleNever = true;
			$scope.settings.toggleCount = true;
			$scope.settings.toggleUntil = $scope.calendar.config.advancedRepeats
				? false
				: true;
			$scope.settings.count = 24;
			$scope.settings.until = '';
			$scope.settings.days = {};
			$scope.settings.days[$scope.edit.start.format('dddd')] = true;
			$scope.settings.numbers = {};
			$scope.settings.numbers[$scope.edit.start.date()] = true;
			$scope.settings.occurence = {};
			if ($scope.edit.start.date() < 8) {
				$scope.settings.occurence.FIRST = true;
			} else if ($scope.edit.start.date() < 15) {
				$scope.settings.occurence.SECOND = true;
			} else if ($scope.edit.start.date() < 22) {
				$scope.settings.occurence.THIRD = true;
			} else if ($scope.edit.start.date() < 29) {
				$scope.settings.occurence.FOURTH = true;
			} else {
				$scope.settings.occurence.LAST = true;
			}
			$scope.settings.toggleByMonthNumber = true;
			$scope.settings.toggleByDay = false;
			$scope.settings.monthNumbers = {};
			$scope.settings.monthNumbers[$scope.edit.start.month() + 1] = true;
		}

		$scope.validateNumber = function (e, value) {
			if (e.key === '-' || (e.key === '0' && !$scope.settings.interval)) {
				e.preventDefault();
			}
		};

		$scope.changeView = function (view) {
			$scope.settings.panel = view;
			$scope.edit.updateDate();
		};

		$scope.cancel = function () {
			if ($scope.edit.recurrence) {
				$scope.edit.recurrence = $scope.edit.event.recurrence;
			}
			$scope.edit.repeatingButton = 'Repeat';
			$scope.edit.isRepeating = false;
			$scope.edit.recurrenceSettings = JSON.parse(
				JSON.stringify($scope.settings)
			);
			$scope.popover.config.show = false;
		};

		//$scope.calendar.date = seedcodeCalendar.get('date');
		$scope.selectDay = function (value) {
			if (
				$scope.settings.toggleByDay === false &&
				$scope.settings.panel === 'Monthly'
			) {
				return;
			}
			if ($scope.settings.days[value]) {
				$scope.settings.days[value] = false;
			} else {
				if ($scope.settings.panel === 'Monthly') {
					//monthly can only have one value
					$scope.settings.days = {};
				}
				$scope.settings.days[value] = true;
			}
			$scope.edit.updateDate();
		};

		$scope.selectItem = function (value) {
			if ($scope.settings.toggleByMonthNumber === false) {
				return;
			}
			if ($scope.settings.numbers[value]) {
				$scope.settings.numbers[value] = false;
			} else {
				if ($scope.settings.panel === 'Monthly') {
					//monthly can only have one value
					$scope.settings.numbers = {};
				}
				$scope.settings.numbers[value] = true;
			}
			$scope.edit.updateDate();
		};

		$scope.selectMonth = function (value) {
			if ($scope.settings.monthNumbers[value]) {
				$scope.settings.monthNumbers[value] = false;
				if (
					$scope.edit.repeatingConfig &&
					!$scope.edit.repeatingConfig.yearlyMultiSelect
				) {
					$scope.settings.monthNumbers = {};
				}
			} else {
				if (
					$scope.edit.repeatingConfig &&
					!$scope.edit.repeatingConfig.yearlyMultiSelect
				) {
					$scope.settings.monthNumbers = {};
				}
				$scope.settings.monthNumbers[value] = true;
			}
			$scope.edit.updateDate();
		};

		$scope.selectOccurence = function (value) {
			if (
				$scope.settings.toggleByDay === false &&
				$scope.settings.panel === 'Monthly'
			) {
				return;
			}
			if ($scope.settings.occurence[value]) {
				$scope.settings.occurence[value] = false;
			} else {
				if ($scope.settings.panel === 'Monthly') {
					//monthly can only have one value
					$scope.settings.occurence = {};
				}
				$scope.settings.occurence[value] = true;
			}
			$scope.edit.updateDate();
		};

		$scope.edit.createRuleObject = function () {
			var daysArray = [];
			var numberArray = [];
			var monthArray = [];

			var ruleObject = {};
			ruleObject.frequency = $scope.settings.panel;
			ruleObject.interval = $scope.settings.interval
				? $scope.settings.interval
				: null;

			ruleObject.count =
				$scope.settings.count && $scope.settings.toggleCount
					? $scope.settings.count
					: null;
			ruleObject.until =
				$scope.settings.until && $scope.settings.toggleUntil
					? $scope.settings.until
					: null;

			//ByDay
			if (
				Object.keys($scope.settings.days).length > 0 &&
				$scope.settings.panel === 'Weekly'
			) {
				var baseArray = [
					'Sunday',
					'Monday',
					'Tuesday',
					'Wednesday',
					'Thursday',
					'Friday',
					'Saturday',
				];
				daysArray = [];
				for (var i = 0; i < baseArray.length; i++) {
					if ($scope.settings.days[baseArray[i]]) {
						daysArray.push(baseArray[i]);
					}
				}
			} else if (
				Object.keys($scope.settings.days).length > 0 &&
				$scope.settings.panel === 'Monthly' &&
				$scope.settings.toggleByDay
			) {
				var day = Object.keys($scope.settings.days)[0];
				var occurence = Object.keys($scope.settings.occurence)[0];
				if (
					$scope.settings.days[day] &&
					$scope.settings.occurence[occurence]
				) {
					daysArray = [occurence + ' ' + day];
				}
			}
			ruleObject.days = daysArray.length > 0 ? daysArray : null;

			if (
				Object.keys($scope.settings.numbers).length > 0 &&
				(($scope.settings.panel === 'Monthly' &&
					$scope.settings.toggleByMonthNumber) ||
					$scope.settings.panel === 'Yearly')
			) {
				var num = Object.keys($scope.settings.numbers)[0];
				if ($scope.settings.numbers[num]) {
					numberArray = [num];
				}
			}
			ruleObject.dayNumbers = numberArray.length > 0 ? numberArray : null;

			if (
				Object.keys($scope.settings.monthNumbers).length > 0 &&
				$scope.settings.panel === 'Yearly'
			) {
				var baseMonthArray = [
					'1',
					'2',
					'3',
					'4',
					'5',
					'6',
					'7',
					'8',
					'9',
					'10',
					'11',
					'12',
				];
				monthArray = [];
				for (var ii = 0; ii < baseMonthArray.length; ii++) {
					if ($scope.settings.monthNumbers[baseMonthArray[ii]]) {
						monthArray.push(baseMonthArray[ii]);
					}
				}
			}
			ruleObject.monthNumbers = monthArray.length > 0 ? monthArray : null;

			return ruleObject;
		};

		$scope.edit.createRequest = function () {
			var number = $scope.edit.start.date();
			var month = $scope.edit.start.month();
			var year = $scope.edit.start.year();
			var newDate;
			if (
				$scope.settings.panel === 'Monthly' &&
				$scope.settings.toggleByMonthNumber
			) {
				for (var thisNumber in $scope.settings.numbers) {
					if ($scope.settings.numbers[thisNumber]) {
						number = thisNumber;
					}
				}
				newDate = moment([year, month, Number(number)]);
			} else if ($scope.settings.panel === 'Yearly') {
				for (var thisMonth in $scope.settings.monthNumbers) {
					if ($scope.settings.monthNumbers[thisMonth]) {
						month = thisMonth - 1;
					}
				}
				newDate = moment([year, Number(month), Number(number)]);
			} else {
				newDate = $scope.edit.start;
			}

			var request = {
				start: {
					dateTime: newDate,
				},
				end: {
					dateTime: $scope.edit.end,
				},
				allDay: $scope.edit.allDay,
				allowAllDay: $scope.edit.schedule.allowAllDay,
			};
			return request;
		};

		$scope.edit.updateDate = $scope.edit.schedule.source
			.calculateEndDateTime
			? function () {
					var ruleObject = $scope.edit.createRuleObject();
					if (!ruleObject.interval) {
						return;
					}

					var request = $scope.edit.createRequest();
					var result = $scope.edit.allDay
						? moment(
								$scope.edit.schedule.source.calculateEndDateTime(
									request,
									ruleObject
								)
						  ).format('L')
						: moment(
								$scope.edit.schedule.source.calculateEndDateTime(
									request,
									ruleObject
								)
						  ).format('L');
					if (result !== 'Invalid date') {
						$scope.settings.until = result;
						$scope.settings.untilFixed = result;
					}
			  }
			: function () {
					return;
			  };

		$scope.edit.updateDate();

		$scope.edit.validateDate = function () {
			if (
				!$scope.settings.toggleUntil ||
				!$scope.edit.repeatingConfig ||
				!$scope.edit.repeatingConfig.footers.until
			) {
				return true;
			}

			var ruleObject = $scope.edit.createRuleObject();

			var request = $scope.edit.createRequest();

			var limit = moment(
				$scope.edit.schedule.source.calculateEndDateTime(
					request,
					ruleObject
				)
			);
			var testDate = moment($scope.settings.until, 'L');
			if (
				testDate.format() === 'Invalid date' ||
				testDate.format('YYYYMMDD') > limit.format('YYYYMMDD')
			) {
				return limit.format('L');
			} else {
				return true;
			}
		};

		$scope.getRule = function () {
			//error capture
			var error = false;

			var dateCheck = $scope.edit.validateDate();
			if (dateCheck !== true) {
				error =
					'The "Stop on" Date must be a vaild date on or before ' +
					dateCheck +
					'.';
			}
			if (error) {
				utilities.showMessage(error, 0, 5000);
				return;
			}

			var ruleObject = $scope.edit.createRuleObject();

			//interval always needed
			if (!ruleObject.interval || ruleObject.interval === 0) {
				error = 'The interval ("repeat every") is a required value.';
			} else if ($scope.settings.panel === 'Weekly') {
				if (!ruleObject.days || ruleObject.days.length === 0) {
					error = 'You must select at least one day of the week.';
				}
			} else if ($scope.settings.panel === 'Monthly') {
				if ($scope.settings.toggleByDay) {
					if (!ruleObject.days || ruleObject.days.length === 0) {
						error =
							'You must select an occurence and a day of the week.';
					}
				} else if ($scope.settings.toggleByMonthNumber) {
					if (
						!ruleObject.dayNumbers ||
						ruleObject.dayNumbers.length === 0
					) {
						error = 'You must select a day of the month.';
					}
				} else {
					error =
						'You must select whether to repeat by a day of the month or by a day of the week.';
				}
			} else if ($scope.settings.panel === 'Yearly') {
				if (
					!ruleObject.monthNumbers ||
					ruleObject.monthNumbers.length === 0
				) {
					error = 'You must select at least one month.';
				}
			}

			if (error) {
				utilities.showMessage(error, 0, 5000);
				return;
			}

			//save settings if we return from popover
			$scope.edit.recurrenceSettings = JSON.parse(
				JSON.stringify($scope.settings)
			);

			//save rule object for updating
			$scope.edit.ruleObject = ruleObject;

			//ByMonthNumber
			//pass start and end objects so we can get the correct timezone for until

			var request = $scope.edit.createRequest();

			//get "rule" according to schedule
			var rule = $scope.edit.schedule.source.getRepeatingRule(
				request,
				ruleObject
			);
			for (var property in rule) {
				$scope.edit[property] = rule[property];
			}
			$scope.popover.config.show = false;
		};

		$scope.toggleStop = function (label, value) {
			if (label === 'toggleUntil') {
				if (value === true) {
					$scope.settings.toggleCount = false;
				}
			}
			if (label === 'toggleCount') {
				if (value === true) {
					$scope.settings.toggleUntil = false;
				}
			}
		};

		$scope.toggleByDay = function (label, value) {
			if (label === 'toggleByMonthNumber') {
				if (value === true) {
					$scope.settings.toggleByDay = false;
				}
			}
			if (label === 'toggleByDay') {
				if (value === true) {
					$scope.settings.toggleByMonthNumber = false;
				}
			}
			$scope.edit.updateDate();
		};
	}
})();
